// ==========================================================================
// BASE / TYPOGRAPHY
// ==========================================================================

body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}


//
// Body Text
// --------------------------------------------------------------------------
p {
  margin: 0 0 $small-spacing;
}

p, li {
  font-weight: 400;
  line-height: $base-line-height;
  margin-bottom: $base-line-height;
  font-size: 1em;
  text-align: center;

  strong {
    font-weight: 700;
  }
}



//
// Links
// --------------------------------------------------------------------------
a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}


//
// Headers
// --------------------------------------------------------------------------

// Generals
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  //color: $ls-blue;

}

// Specifics
h1 {
  font-weight: 700;
  font-size: 1.4em;
  letter-spacing: -0.04em;
  line-height: 1em;
  color: darken( $blue, 10% );
}

h2 {
  font-weight: 700;
  font-size: 1.25em;
  letter-spacing: -0.04em;
   color: darken( $blue, 10% );
}

h3 {
  font-weight: 700;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #666;
}

h4 {
  font-size:  0.9em;
  font-weight: 400;
  color: #333;
  margin-bottom: 0;
}

h5 {}


@include grid-media($grid-sm){
  h1 { font-size: 1.75em; }
  h2 { font-size: 1.5em; }
  h3 { font-size: 1em; }
  h4 {}
  h5 {}
}

@include grid-media($grid-md){
  h1 { font-size: 2.25em; }
  h2 { font-size: 1.75em; }
  h3 { font-size: 1.15em; }
  h4 {}
  h5 {}
}

@include grid-media($grid-lg){
  h1 { font-size: 3em; }
  h2 { font-size: 2em; }
  h3 { font-size: 1.25em; }
  h4 {}
  h5 {}
}

@include grid-media($grid-xlg){
  h1 {}
  h2 {}
  h3 {}
  h4 {}
  h5 {}
}


//
// Helpers
// --------------------------------------------------------------------------
.sm-caps {
  text-transform: lowercase;
  font-variant: small-caps;
}
