// ==========================================================================
// PAGES / HOME
// ==========================================================================


//
// Site Layout
// --------------------------------------------------------------------------
.post-content > p {
	text-align: start;
}
.blog-lp {

	@include breakpoint($xxxs){
		width: 385px !important;
	}
	
	#page-content {
		display: grid;
		grid-gap: 10px;
		grid-template-areas: 
			"post"
			"meta"
			"ad";
		grid-template-columns: 1fr;
			

		@include grid-media($grid-sm){
			grid-template-columns: 1fr 2fr;
			grid-template-areas: 
				"meta post"
				". ad";
			//grid-template-columns: 2fr 4fr;
		}

		@include grid-media($grid-md){
			grid-template-columns: 1fr 3fr;
			// grid-template-areas: 
			// 	"meta meta meta post post post post post post post post post"
			// 	"ad ad ad ad ad ad ad ad ad ad ad ad";

		}

		@include grid-media($grid-lg){
			grid-gap: 20px;
			grid-template-columns: 1fr 3fr 200px;
			grid-template-areas: 
				"meta post ad"
		}
	}

	.post {	grid-area: post; }
	.post-meta { grid-area: meta; }
	.siderail {	grid-area: ad; }
}


//
// Post Content
// --------------------------------------------------------------------------
.post {
	header {
		margin-bottom: $base-spacing;
		h1 {
			padding-top: $small-spacing;
		}
		h3 {
			font-weight: 400;
			letter-spacing: 0.02em;
		}
	}

	h2 {
		padding-top: $small-spacing;
	}

	.btn-holder {
		margin-top: $base-spacing;
		margin-bottom: $base-spacing;
		text-align: center;

		.btn {
			margin-bottom: $small-spacing;
		}
	}

}

.hero {
	margin-bottom: $small-spacing;
}
.post-date {
	background: $blue-light;
	color: $blue;
	padding: 6px 12px 6px 40px;
	box-shadow: 6px 8px 0 0 rgba(0,0,0,0.05);
	margin-left: -9px;
	@include breakpoint($sm){
		margin-left: -24px;
	}
}

//
// Post Meta
// --------------------------------------------------------------------------
.post-meta {
	padding-top: $base-spacing;

	@include grid-media($grid-md){
		padding-top: $base-spacing * 3;
	}
}

.author {
	img {
		width: 50%;
	}
}
//
// Post Share
// --------------------------------------------------------------------------

$facebook: #3b5998;
$email:	shade( $blue-light, 5% );

.post-share { }
.share-link {
	@include clearfix;
	display: block;
	background: hsl(19,0,90);
	color: #666;
	margin-bottom: $small-spacing;
	font-weight: 800;
	border-radius: 4px;
	transition: background-color 0.15s ease;

	span {
		display: block;
		float: left;
		padding: 10px 4px 4px 10px;

		transition: padding 0.15s ease;
	}

	&:before {
		content: '';
		width: 40px;
		height: 40px;
		display: block;
		top: 0;
		left: 0;
		float: left;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		background-size: 50%;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&:hover {
		span {
			padding-left: 14px;
		}
	}

	/* Special Links */
	&.share-facebook {
		&:before { 
			background-color: #3b5998; 
			background-image: url("../img/icons/fb.png");
		}
		&:hover {
			color: #fff;
			background: shade( $facebook, 10% );
		}
	}
	&.share-email {
		&:before { 
			background-color: $email; 
			background-image: url("../img/icons/email.png");
			background-size: 60%;
		}
		&:hover {
			color: #fff;
			background: shade( $email, 5% );
		}
	}
}

//
// Testimonials
// --------------------------------------------------------------------------
.testimonial-list {
	padding-top: $base-spacing;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;

	@include grid-media($grid-sm){
		display: block;
	}
}
.testimonial {
	background: #f8f8f8;
	flex-grow: 1;
	width: 50%;

	header {
		@include clearfix;
		border-bottom: 1px solid #f2f2f2;
	}
	div {
		padding: $small-spacing;
	}

	img {
		width: 60px;
		margin-right: 10px;
		float: left;
		position: relative;
		padding: 6px
	}
	h4 {
		padding: 10px 10px 0 0;
		span {
			font-style: italic;
			font-size: 80%;
			color: #999;
			display: block;
		}
	}
	

	p {
		font-size: 80%;
		margin-bottom: 0;
		text-align: start;
	}

	@include grid-media($grid-sm){
		width: 100%;
		margin-bottom: $base-spacing;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

	}
}

/* Siderail */
.siderail {
	padding-top: $base-spacing;

	@include grid-media($grid-lg){
		padding-top: $base-spacing * 4;

	}
}


.ad-block {
	border: 2px solid #ccc;
	border-radius: 4px;
	text-align: center;
	margin-bottom: $base-spacing;


	header {
		padding: $small-spacing;
		background: #ccc;
	}
	div {
		padding: $small-spacing;
	}
	h3 {
		text-transform: none;
		margin: 0;
		font-size: 100%;
		letter-spacing: 0;
		color: $blue;
	}
	p {
		font-size: 90%;
	}

	.btn {
		margin-bottom: $small-spacing * 0.5;
		width: 100%;
		background-color: $red;

		&:hover {
			background-color: shade($red, 20%);
		}
	}

	@include grid-media($grid-md) {
		position: -webkit-sticky;
  		position: sticky;
  		top: $base-spacing;
	}
}

