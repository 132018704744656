// ==========================================================================
// LAYOUT / FORMS
// ==========================================================================
$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 400;
  margin-bottom: $small-spacing / 2;
}

input,
select,
textarea,
#age-input {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

#{$all-text-inputs},
#age-input {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $_form-box-shadow;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}


//
// Form Groups
// --------------------------------------------------------------------------
.form-group {
  margin-bottom: $base-spacing;
  position: relative;

  > label {
    font-size: 1em;
    margin-bottom: 0;
    padding: 3px;
  }

  span {
    label {
      font-size:90%;
    }

    .desc {
      font-size:70%;
      text-transform: uppercase;
      padding-left: $base-spacing / 3;
      padding-top: 4px;
      letter-spacing: 0.04em;
    }
  }

  input {
    padding: $small-spacing;
    font-size: 1.25em;
  }

  // Section Specifics
  &--yesno {

    input {
      opacity: 0;
      position: absolute;
      z-index: -10;
      top: 0;

      &:focus + label.btn {
        background: $green;
      }
    }

    .btn {
      background-color: #fff;
      border: 1px solid tint( $medium-gray, 50% );
      color: $green;
      font-size: 120%; 

      &:hover {
        background-color: $green;
        color: #fff;
        border-color: shade( $green, 4% );
      }

      &--selected {
        color: tint( $green, 50% );
        border-color: shade($green, 10% );
        background-color: shade( $green, 10%);
        box-shadow: 0 4px 10px rgba( $blue, 0.1) inset, 0 2px 0 rgba( $blue, 0.1) inset;

        &:hover {
          color: #fff;
          border-color: shade($green, 10% );
          background-color: shade( $green, 12%);
          box-shadow: 0 4px 10px rgba( $blue, 0.1) inset, 0 2px 0 rgba( $blue, 0.1) inset;
        }
      }
    }

    span{
      @include grid-column(6);
      text-align: left;
      padding-top: $small-spacing;

      &:nth-child(odd) {
        text-align: right;
      }
    }

  }


  &--radio {
    [type="radio"] {
      width: 1em !important;
       display: inline-block;
    }
    span {
      clear: left;

      label {
        font-weight: 400;
        display: inline-block;
        font-size: $base-font-size;
        text-transform: inherit;
        letter-spacing: 0;
        color: $dark-gray;
      }
      // input {
      //   flex: 1 1 auto;
      // }

    }

  }

  &--name {
    span {
      @include grid-column(6);
    }
  }

  //Name
  &--name {
    > div {
      display: flex;
      flex-direction: row;

      .field {
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }



  &--btn {
    text-align: center;
    padding-top: $small-spacing;

    .btn {
        background: $red;

      
      @include grid-media($grid-lg){
        padding: 1.5em 3em;
        font-size: $base-font-size * 1.2;
      }

    }
  }

}





//
// Buttons 
// --------------------------------------------------------------------------
.btn-group {
  text-align: center;
}

/* Parsley Errors */
.parsley-errors-list {
  // background: lighten( red, 45%);
  // border: 1px solid lighten( red, 42%);
  margin-bottom: 1em;
  padding: 0.5em;
  color: red;

  &.filled li {
    font-size: 0.9em;
    font-weight: 800;
  }

}

.form-group--yesno > span > .error {
	float: none !important;
	text-align: center !important;
	margin: 0 auto !important;
}

.form-container {

  .legal {
    font-size: 70%;
    
      @include grid-media($grid-md){
        font-size: 75%;
      }
      @include grid-media($grid-lg){
        font-size: 85%;
      }
  }

}




