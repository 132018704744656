// ==========================================================================
// COMPONENTS / MODALS
// ==========================================================================

.modal {
    text-align: center;
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
}

#spinner {
    position: relative;
    top: 20%;
}

.modal #spinner img {
    width: 10%;
    -webkit-animation-name: bounce;
    -webkit-animation-duration: 0.5s;
  	-webkit-animation-direction: alternate;
  	-webkit-animation-timing-function: cubic-bezier(.5,0.05,1,.5);
  	-webkit-animation-iteration-count: infinite;
    
    -moz-animation-name: bounce;
    -moz-animation-duration: 0.5s;
    -moz-animation-direction: alternate;
    -moz-animation-timing-function: cubic-bezier(.5,0.05,1,.5);
    -moz-animation-iteration-count: infinite;
    
    -ms-animation-name: bounce;
    -ms-animation-duration: 0.5s;
    -ms-animation-direction: alternate;
    -ms-animation-timing-function: cubic-bezier(.5,0.05,1,.5);
    -ms-animation-iteration-count: infinite;
    
    animation-name: bounce;
    animation-duration: 0.5s;
  	animation-direction: alternate;
  	animation-timing-function: cubic-bezier(.5,0.05,1,.5);
  	animation-iteration-count: infinite;
    
}

body.loading .modal {
    overflow: hidden;
}
body.loading .modal {
    display: block;
}

@keyframes bounce {
  from { 
  	transform: translate3d(0, -75px, 0);     
  }
  to { 
  	transform: translate3d(0, 0, 0); 
  }
}

@-ms-keyframes bounce {
    from {
        -ms-transform: translate(0,-75px,0);
    }
    to {
        -ms-transform: translate(0, 0, 0);
    }
}

@-moz-keyframes bounce {
    from {
        -moz-transform: translate(0,-75px,0);
    }
    to {
        -moz-transform: translate(0, 0, 0);
    }
}

@-webkit-keyframes bounce {
  from { 
    -webkit-transform: translate3d(0, -75px, 0);
  }
  to   { 
    -webkit-transform: translate3d(0, 0, 0);
  }
}
