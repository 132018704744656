// ==========================================================================
// PAGES / FORM
// ==========================================================================
.legal-text {
	margin-top: 2em;
	font-size: .5em;
}

#age-input, .field > select {
	height: 3em;
	font-size: 1.3em;
	text-align: center;
    text-align-last: center;
    height: auto;
    font-size: 1em;
    @include breakpoint($sm) {
    		font-size: 1.6em;
    }
}

#form-container {
	@include grid-media($grid-md) {
		width: 780px;
		margin-left: auto;
		margin-right: auto;
	}
 
	header {
		padding-top: $small-spacing;
		padding-bottom: $base-spacing * 2;
		text-align: center;
	}
	h1 {
		font-size: 140%;
		margin-bottom: $small-spacing;
		color: #666;
		letter-spacing: 0;
	}

	.module {
		padding-top: 0;
		margin-bottom: $base-spacing;
	}
}

.hidden {
	display: none;
}

// Progress Bar
.progress {
	background: rgba(0,0,0,0.05);
	border-radius: 1em;
	height: 30px;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 1px 2px 0 inset rgba(0,0,0,0.1);
	padding: 6px;

	span {
		display: block;
	 	height: 100%;
	  	border-top-right-radius: 8px;
	  	border-bottom-right-radius: 8px;
	  	border-top-left-radius: 20px;
	  	border-bottom-left-radius: 20px;
	  	background-color: $blue-light;
	  	position: relative;
	  	overflow: hidden;
	  	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);
	  	@include gradient-vertical( shade( $blue-light, 10% ), shade( $blue-light, 15% ), 40%, 100%);
	}
}

// Customer Service Helper
.helper {
	@include grid-container();

	img {
		@include grid-column(2);
		@include grid-push(5);
		border-radius: 100%;
		margin-top: -30px;
	//	margin-bottom: $small-spacing;
		box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
	}
}

// Form Step
.form-step {
	padding-bottom: $base-spacing;
	
	header {
		text-align: center;
		padding-bottom: $base-spacing !important;
	}
	h2 {
		color: shade( $blue, 20% );
		font-weight: 300;
		margin-bottom: 0;
	}
}
