// ==========================================================================
// LAYOUT / SITE
// ==========================================================================


//
// Globals
// --------------------------------------------------------------------------

@-webkit-viewport {
width : device-width; }

@-moz-viewport {
width : device-width; }

@-ms-viewport {
width : device-width; }

@-o-viewport {
width : device-width; }

@viewport {
width : device-width; }


html {
	background: $blue;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten( $base-font-color, 75% );
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
	background-color: transparent;
	font-family : $base-font-family;
	line-height : $base-line-height;
	color : $base-font-color;
	-webkit-font-smoothing: antialiased;
}

::-moz-selection {
	//background-color : lighten( $action-color, 10% );
	background-color : lighten( $red, 10% );
	color : $red;
	text-shadow : none; }

::selection {
	//background-color : lighten( $action-color, 10% );
	background-color : lighten( $red, 10% );
	color : $red;
	text-shadow : none; }


//
// Content Wrappers
// --------------------------------------------------------------------------
.wrapper,
.wrapper--full {
	padding-left: 5%;
	padding-right: 5%;
	width: auto;
	position: relative;
	@include grid-container;

	@include grid-media($grid-sm){
		padding-left: 2%;
		padding-right: 2%;
	}
}

.wrapper {
	@include grid-media($grid-lg){
		padding-left: 0;
		padding-right: 0;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	@include grid-media($grid-xlg){
		width: 85%;
	}

	@include grid-media($grid-xxlg){
		width: 1300px;
	}
}

.module {
	background: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
	padding: 3em 0.5em 1.5em;
	padding-top: $base-spacing * 2;
	margin-bottom: $base-spacing;
	@include breakpoint($sm){
		padding: $base-spacing;
	}
}

#primary-content {
	background: #f2f2f2;
	z-index: 0;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;
}

