// ==========================================================================
//  LAYOUT / MASTHEAD
// ==========================================================================

#masthead {
	background: #fff;
	padding-top: $small-spacing;
	padding-bottom: $small-spacing;
	box-shadow: 0 1px 5px 0 rgba(0,0,0,0.04);
	z-index: 10;
	position: relative;

	.wrapper {
		@include grid-container;
	}

	.logo {
		@include breakpoint($sm){
			@include grid-column(5);
		}
	}
	.cta {
		@include grid-column(5);
		text-align: right;
		margin-top: $small-spacing;
		width: 100%;
		text-align: center;
		@include breakpoint($sm){
			text-align: none;
		}
	}

	.btn {
		text-transform: none;
		letter-spacing: 0;
		background: $blue-light;
		color: darken( $blue, 5% );
		&:hover {
			background: $red-dark;
			color: #fff;
		}
	}


	@include grid-media($grid-sm){
		.logo { @include grid-column(3); }
		.cta { @include grid-column(9); }
	}
	@include grid-media($grid-lg){
		.logo { @include grid-column(2.5); }
		.cta { @include grid-column(9.5); }
	}
}
