// ==========================================================================
// LAYOUT / FOOTER
// ==========================================================================

#page-footer {
	background: $blue;
	padding-top: $base-spacing * 2;
	padding-bottom: $base-spacing * 4;
	color: $blue-light;
	text-align: center;

	
	p, li {
		font-size: 90%;
	}

	a {
		color: $blue-light;

		&:hover {
			color: #fff;
		}
	}

	ul {
		float: none;
		margin: 0 auto;
		li {
			padding: 0 1em;
			float: none;
			display: inline-block;
		}		
		@include breakpoint($md){
			float: right;
			li {
				float: left;
			}
		}
	}

	@include grid-media($grid-md){
		text-align: left;
		font-size: 1em;

		.copyright,
		nav {
			@include grid-column(6);
		}
		.copyright {
			
		}

		nav {
			a {
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}
}